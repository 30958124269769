#my_toast_container {
  position: fixed;
  bottom: 6rem;
  z-index: 999;
  width: 100%;
  text-align: center;
  > div {
    display: inline-block;
    width: 90vw;
    background-color: #000;
    opacity: 0;
    border-radius: 0.2rem;
    color: #eee;
    text-align: center;
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    word-break: keep-all;
  }

  pointer-events: none;
}
