#splash {
  position: relative;
  z-index: 1000;
  background: #fff;
  width: 100vw;
  height: 100vh;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
  }

  .splash-img {
    object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
