.login-btn-wrap {
  > div {
    height: 3rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 0.1rem;
    text-align: center;
    > img {
      width: auto;
      height: 100%;
    }
    > a {
      height: 3rem;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 1rem;

      > img {
        width: auto;
        height: 100%;
      }
    }
  }
}
