#image_full_screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 999;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;

  > #image_full_screen_header {
    color: #fff;
    padding: 1rem;
  }

  > #image_full_screen_content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  #full_screen_content_img {
    position: relative;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    background-size: contain;
    background-origin: content-box;
    border: 1rem solid #fff;
  }

  #full_screen_content_pre {
    position: fixed;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: -1px 0 #999, 0 1px #999, 1px 0 #999, 0 -1px #999;
    padding: 1rem;
    > img {
      width: 50px;
    }
  }

  #full_screen_content_next {
    position: fixed;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: -1px 0 #999, 0 1px #999, 1px 0 #999, 0 -1px #999;
    > img {
      width: 50px;
    }
  }

  > #image_full_screen_footer {
    color: #fff;
    text-align: center;
  }
}
