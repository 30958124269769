@import "custom_color.scss";

.dropdown .content {
  height: auto;
  overflow: hidden;

  &.hidden {
    max-height: 0;
  }
}

#service_menu {
  > div {
    color: $brown-grey;
    border-bottom: 1px solid $very-light-pink;

    &.active {
      border-bottom: 2px solid #000;
      color: #000;
    }
  }
}
