@import "custom_color.scss";

#app_modal_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;

  .contens_wrap {
    max-height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    > div {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .image-wrap {
    text-align: center;
    margin: 0;
    padding: 0;

    #app_detail_main_image {
      max-width: 100vw;
      max-height: 100vh;
      width: auto;
    }
  }
  div.app-detail-info-table {
    padding: 0.5rem;
    overflow: hidden;
    font-size: 0.8rem;

    > table {
      border: 1px solid $brown-grey;
      table-layout: fixed;

      border-collapse: separate;
      border-spacing: 0.5rem;

      font-family: "NanumSquareR";

      * {
        border: none;
      }

      td {
        line-height: 0.2rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
          color: $brown-grey;

          border-right: 2px solid $very-light-pink;
        }

        &:first-child {
          text-align: justify;
        }
      }
    }
  }

  .detail-content-wrap {
    white-space: pre-wrap;
    height: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;

    &.full {
      height: auto;
    }
  }
}

.content-link-back > span::after {
  position: fixed;
  left: 0.8em;
  top: 0;
  content: "";
  width: 1rem; /* 사이즈 */
  height: 1rem; /* 사이즈 */
  border-top: 3px solid #000; /* 선 두께 */
  border-right: 3px solid #000; /* 선 두께 */
  transform: rotate(225deg) translate(-50%, -50%); /* 각도 */
}

.content-link-close > button {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  color: #000;
  opacity: 1;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 0;
}

.content-favorites {
  padding-left: 0.5rem;
  color: #000;
  opacity: 1;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1rem;
  flex: 0 0 1rem;
}

#app_detail_menu_wrap {
  padding: 0 0.5rem;
  display: flex;
  white-space: nowrap;

  > div {
    padding: 0.2rem 0.5rem;
    margin: 0.4rem 0.5rem 0 0.5rem;
    box-sizing: border-box;

    > a {
      color: $brown-grey;
    }

    &.active {
      border-bottom: 2px solid #000;
      font-weight: bold;

      > a {
        color: $black;
      }
    }
  }
}

.app-detail-image-wrap {
  > div {
    margin-left: 0.5rem;
  }

  > :first-child {
    margin-left: 1.5rem;
  }

  > :last-child {
    margin-right: 1.5rem;
  }
}

#detail-footer {
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.14);
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  font-size: 0.8rem;
  white-space: nowrap;
  color: $rouge;

  a {
    color: $rouge;
  }

  div.disabled {
    pointer-events: none;
    > * {
      color: $brown-grey !important;
    }
  }

  img {
    width: 1.5rem;
    margin-bottom: 0.3rem;
  }

  #question_count_wrap {
    position: relative;

    #question_count {
      position: absolute;
      right: -0.3rem;
      top: -0.4rem;
      background-color: $rouge;
      width: 1.3rem;
      height: 1.3rem;
      line-height: 1.3rem;
      border-radius: 50%;
      color: #fff;
      font-size: 0.8rem;
    }
  }
}
