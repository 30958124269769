@import "custom_color.scss";

#app_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;

  > * {
    pointer-events: all;
  }

  input {
    pointer-events: all;
  }

  div {
    pointer-events: none;
    button {
      pointer-events: all;
    }
  }

  .search {
    position: relative;

    &:before {
      content: "";
      background-image: url("/assets/images/icon/icon_search.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 0.8rem;
      top: 0.7rem;
      width: 18px;
      height: 18px;
    }
    > input {
      height: 2.5rem;
      padding-left: 2.5rem;
      border: solid 1px #e5e5e5;
      box-shadow: 0 10px 15px 0 rgba(186, 186, 186, 0.5);

      &::placeholder {
        color: #999;
      }
    }
  }

  #btn_setting {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    text-align: center;
  }

  #filter_wrap {
    overflow: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    pointer-events: all;

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    .btn {
      box-shadow: 0 0 5px #666;
      border: none;
    }
  }

  #btn_coord {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 0;
    padding: 5px;
    background: #fff;
    border: 1px solid $very-light-pink;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    pointer-events: all;
  }

  #zoom_wrap {
    > div > div {
      border: 1px solid $very-light-pink;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      outline: none;

      width: 42.5px;

      > button:first-child {
        border-bottom: 1px solid $very-light-pink;
      }
    }

    button {
      width: 100%;
      height: 42px;
      line-height: 0;
      font-size: 2.5rem;
      padding: 0.5rem 0.5rem 0.3rem 0.5rem;
      color: #999;
      font-weight: 100;
      vertical-align: middle;
      border-radius: 0;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  #app_region_filter {
    position: absolute;
    top: 9.5rem;
    width: 100vw;
    pointer-events: all;
    box-sizing: border-box;

    * {
      pointer-events: all;
    }

    #app_region_filter_items {
      white-space: nowrap;
      font-size: 0.8rem;
      max-height: calc(100vh - 17rem);
      overflow-y: auto;
      font-family: "NanumSquareR";
      color: #21293a;

      .selected {
        background-color: $rouge;
        color: #fff;
      }
    }
  }

  .filter-item > button {
    padding: 0.3rem 1rem 0.2rem 1rem;
  }
}

#filter_result {
  margin: 2.5rem 0.5rem 0 0.5rem;
  background-color: #fff;
  padding: 0.5rem 1rem;

  color: $rouge;
  border: solid 1px #e5e5e5;
  box-shadow: 0 10px 15px 0 rgba(186, 186, 186, 0.5);
  border-radius: 0.3rem;

  .btn-close {
    position: absolute;
    right: 1rem;
  }
}

#app_header #list_btn {
  pointer-events: all;
}

#list_btn {
  box-shadow: 0 10px 15px 0 rgba(186, 186, 186, 0.5);
  border: solid 1px var(--brown-grey);
  background-color: #fff;
  width: 10rem;
  margin: auto;
  padding: 0.6rem;
  border-radius: 3rem;
}
