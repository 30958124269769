@import "custom_color.scss";

#app_bottom {
  z-index: 100;
  > #app_bottom_btns {
    position: relative;
    background-color: #fff;
    margin: 0;

    > div {
      padding: 0.7rem;
      color: $brown-grey;

      &:first-child {
        border-right: 1px solid #e5e5e5;
      }
    }
  }

  > #bottom_sheet {
    position: absolute;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: calc(100vh - 60px);
    border-radius: 1rem 1rem 0 0;
    box-shadow: inset 0 0 1px #666;

    #content_list_wrap {
      height: calc(100%);
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.search #content_list_wrap {
      height: 100%;
    }

    &::after {
      position: absolute;
      content: "";
      width: 3rem;
      height: 5px;
      background: #872a2e;
      top: 0.4rem;
      left: 50%;
      border-radius: 1rem;
      transform: translateX(-50%);
    }

    #content_list_wrap {
      pointer-events: all;
    }
  }

  > #bottom_sheet.full {
    #content_list_wrap {
      pointer-events: all;
    }
  }
}

#name_search:focus {
  border: 2px solid #872a2e;
  box-shadow: none;
}
