@import "custom_color.scss";

#map_wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

#map_wrap > #map {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.content-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

.content-wrap > * {
  pointer-events: all;
}

// 마커
.app-icon {
  font-size: 0.8rem;
  display: flex;
  border-radius: 0.3rem;
  background-color: #253a6c;
  white-space: nowrap;
  box-sizing: border-box;
  color: #fff;
  font-family: "NanumSquareR";

  position: absolute;
  left: -25px;
  bottom: 30px;

  &::after {
    content: " ";
    width: 10px;
    height: 20px;
    margin: 23px 327px 0 22px;

    position: absolute;
    left: 0px;
    top: 0;

    z-index: -1;
    border-top: 20px solid #253a6c;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  &.focus {
    &::after {
      content: " ";
      border-top: 30px solid #ffdd00;
    }
  }

  &.blue {
    background-color: #1f5bc2;

    .marker-count {
      border-color: #1f5bc2;
    }
  }

  &.focus {
    background-color: #fff;
    border: 1px solid #ffdd00;
    color: #000;
  }

  // 시대 검색 마커
  &.age-marker,
  &.region-marker {
    background-color: #93202a;
    color: #fff;

    .app-icon-left {
      border-color: #93202a;
      color: #93202a;
    }

    &::after {
      content: " ";
      border-top: 20px solid #93202a;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .app-icon-left {
    padding: 0.2rem 0.3rem 0.1rem 0.2rem;
    border: 1px solid #253a6c;
    border-radius: 0.3rem 0 0 0.3rem;
    background-color: #d8d8d8;
    min-width: 2rem;
    text-align: center;
    color: #253a6c;
  }

  &.focus {
    z-index: 100;
  }
  &.focus .app-icon-left {
    background-color: #ffdd00;
    border: 1px solid #ffdd00;
  }

  .app-icon-right {
    padding: 0.2rem 0.3rem 0.1rem 0.2rem;
  }

  .marker-count {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    padding: 0.1rem 0.2rem 0.2rem 0.2rem;
    line-height: 100%;
    border: 1px solid #000;
    border-radius: 0.5rem;
    background-color: #fff;
    font-size: 0.5rem;
    color: #000;
  }
}

.btn.btn-white {
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: inset 0 0 2px #666;
}

.arrow-prev {
  position: relative;
}

.arrow-prev::after {
  position: absolute;
  left: 0;
  top: -0.4rem;
  content: url("/assets/images/icon/icon_back.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;

  transform: scale(0.5);
}

.btn-close.btn-close-custom {
  top: 0.7rem;
  right: 1rem;
  background-image: url("/assets/images/icon/icon_close_black.png");
}

#page_wrap > div {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 110;
  background: #fff;
}
.Toastify {
  z-index: 999;
}

.content-link {
  display: contents;
  text-decoration: none;
  color: #000;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 0;
}

::-webkit-scrollbar:horizontal {
  height: 0;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

textarea::placeholder {
  color: $brown-grey !important;
}

#my_wrap {
  height: 7rem;

  div.active {
    background-color: #eee;
  }
}

.btn-close {
  background-image: url("/assets/images/icon/icon_close.png");
}

// 네이버 약관
#logo_html_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-flow: column;

  z-index: 9999;

  > iframe {
    width: 100vw;
    height: 100%;
    z-index: 0;
  }
}
