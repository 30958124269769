@import "custom_color.scss";

$theme-colors: (
  "primary": #007bff,
  "secondary": #6c757d,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
  "dark-slate-blue": $dark-slate-blue,
  "rouge": $rouge,
  "brown-grey": $brown-grey,
  "very-light-pink": $very-light-pink,
);

@import "node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "NanumSquareB";
  src: url("/assets/fonts/NanumSquareB.otf") format("opentype"),
    url("/assets/fonts/NanumSquareB.ttf") format("truetype");
  unicode-range: U+0020-007E, U+AC00-D7A3;
}

@font-face {
  font-family: "NanumSquareR";
  src: url("/assets/fonts/NanumSquareR.otf") format("opentype"),
    url("/assets/fonts/NanumSquareR.ttf") format("truetype");
  unicode-range: U+0020-007E, U+AC00-D7A3;
}

@font-face {
  font-family: "NotoSansCJKkr-B";
  font-weight: 700;
  src: local("※"), url("/assets/fonts/NotoSansCJKkr-Bold.woff") format("woff"),
    url("/assets/fonts/NotoSansCJKkr-Bold.eot") format("embedded-opentype"),
    url("/assets/fonts/NotoSansCJKkr-Bold.otf") format("opentype");
}

@font-face {
  font-family: "NotoSansCJKkr-R";
  font-weight: 100;
  src: local("※"), url("/assets/fonts/NotoSansCJKkr-Medium.woff") format("woff"),
    url("/assets/fonts/NotoSansCJKkr-Medium.eot") format("embedded-opentype"),
    url("/assets/fonts/NotoSansCJKkr-Medium.otf") format("opentype");
}

html,
body,
#root {
  height: 100%;
  user-select: none;
  font-family: "NanumSquareB", "NotoSansCJKkr-B";
  font-size: 17px;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.white-space-nowrap {
  white-space: nowrap;
}

/* wysiwyg */
.rdw-dropdown-selectedtext {
  color: black;
  text-decoration: none;
}

/* ckeditor */
.ck-editor__editable {
  min-height: 200px;
}

.fs-7 {
  font-size: 0.8rem;
}

.fs-8 {
  font-size: 0.6rem;
}

.bg-gray {
  background-color: #eee;
}

.text-gray {
  color: #eee;
}

.rounded-4 {
  border-radius: 1rem;
}

.noresize {
  resize: none;
}

.sorted > div * {
  pointer-events: none;
}

.cul-update-form .updated {
  color: red;
}

.fw-r {
  font-family: "NanumSquareR", "NotoSansCJKkr-B";
}

.form-check-input {
  color: #ae5e4e;
  border-color: #ae5e4e;
  background-color: #fff;
  &:checked {
    background-color: #ae5e4e;
    border-color: #ae5e4e;
  }

  &[color="dark-slate-blue"] {
    color: $dark-slate-blue;
    border-color: $dark-slate-blue;

    &:checked {
      background-color: $dark-slate-blue;
      border-color: $dark-slate-blue;
    }
  }
}
