@import "/src/styles/custom_color.scss";

#my_confirm_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  &.actived::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
    pointer-events: all;
  }

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;
    min-width: 324px;
    width: auto;
    margin: 0;
    border-radius: 0.3rem;
    overflow: hidden;

    pointer-events: all;

    #my_confirm_text {
      color: $brown-grey;
      padding: 1.2rem;
      min-height: 5.5rem;
      white-space: pre;
    }

    #my_confirm_btns {
      background-color: $dark-slate-blue;
      color: #fff;
      margin: 0;
      padding: 0;

      > div {
        text-align: center;
        padding: 1rem;

        &:first-child {
          border-right: 1px solid #fff;
        }
      }
    }
  }

  pointer-events: none;
}
